@keyframes mdx-heart {
  0%, 40%, 80%, 100% {
    transform: scale(1);
  }
  20%, 60% {
    transform: scale(1.15);
  }
}
.md-typeset figure > p + figcaption {
  margin-top: -1.2rem;
}
.md-typeset .twitter {
  color: #00acee;
}
.md-typeset .mdx-video {
  width: auto;
}
.md-typeset .mdx-video__inner {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.138%;
}
.md-typeset .mdx-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: none;
}
.md-typeset .mdx-heart {
  animation: mdx-heart 1000ms infinite;
}
.md-typeset .mdx-insiders {
  color: #e91e63;
}
.md-typeset .mdx-switch button {
  cursor: pointer;
  transition: opacity 250ms;
}
.md-typeset .mdx-switch button:focus, .md-typeset .mdx-switch button:hover {
  opacity: 0.75;
}
.md-typeset .mdx-switch button > code {
  display: block;
  color: var(--md-primary-bg-color);
  background-color: var(--md-primary-fg-color);
}
.md-typeset .mdx-columns ol,
.md-typeset .mdx-columns ul {
  columns: 2;
}
@media screen and (max-width: 29.9375em) {
  .md-typeset .mdx-columns ol,
.md-typeset .mdx-columns ul {
    columns: initial;
  }
}
.md-typeset .mdx-columns li {
  break-inside: avoid;
}

.md-announce a,
.md-announce a:focus,
.md-announce a:hover {
  color: currentColor;
}
.md-announce strong {
  white-space: nowrap;
}
.md-announce .twitter {
  margin-left: 0.2em;
}

.mdx-container {
  padding-top: 1rem;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1123 258'><path d='M1124,2c0,0 0,256 0,256l-1125,0l0,-48c0,0 16,5 55,5c116,0 197,-92 325,-92c121,0 114,46 254,46c140,0 214,-167 572,-166Z' style='fill: hsla(0, 0%, 100%, 1)' /></svg>") no-repeat bottom, linear-gradient(to bottom, var(--md-primary-fg-color), #a63fd9 99%, var(--md-default-bg-color) 99%);
}
[data-md-color-scheme=slate] .mdx-container {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1123 258'><path d='M1124,2c0,0 0,256 0,256l-1125,0l0,-48c0,0 16,5 55,5c116,0 197,-92 325,-92c121,0 114,46 254,46c140,0 214,-167 572,-166Z' style='fill: hsla(232, 15%, 21%, 1)' /></svg>") no-repeat bottom, linear-gradient(to bottom, var(--md-primary-fg-color), #a63fd9 99%, var(--md-default-bg-color) 99%);
}

.mdx-hero {
  margin: 0 0.8rem;
  color: var(--md-primary-bg-color);
}
.mdx-hero h1 {
  margin-bottom: 1rem;
  color: currentColor;
  font-weight: 700;
}
@media screen and (max-width: 29.9375em) {
  .mdx-hero h1 {
    font-size: 1.4rem;
  }
}
.mdx-hero__content {
  padding-bottom: 6rem;
}
@media screen and (min-width: 60em) {
  .mdx-hero {
    display: flex;
    align-items: stretch;
  }
  .mdx-hero__content {
    max-width: 19rem;
    margin-top: 3.5rem;
    padding-bottom: 14vw;
  }
  .mdx-hero__image {
    order: 1;
    width: 38rem;
    transform: translateX(4rem);
  }
}
@media screen and (min-width: 76.25em) {
  .mdx-hero__image {
    transform: translateX(8rem);
  }
}
.mdx-hero .md-button {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  color: var(--md-primary-bg-color);
}
.mdx-hero .md-button:focus, .mdx-hero .md-button:hover {
  color: var(--md-accent-bg-color);
  background-color: var(--md-accent-fg-color);
  border-color: var(--md-accent-fg-color);
}
.mdx-hero .md-button--primary {
  color: #894da8;
  background-color: var(--md-primary-bg-color);
  border-color: var(--md-primary-bg-color);
}

.md-typeset .mdx-iconsearch {
  position: relative;
  background-color: var(--md-default-bg-color);
  border-radius: 0.1rem;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1), 0 0.025rem 0.05rem rgba(0, 0, 0, 0.1);
  transition: box-shadow 125ms;
}
.md-typeset .mdx-iconsearch:focus-within, .md-typeset .mdx-iconsearch:hover {
  box-shadow: 0 0.4rem 1rem rgba(0, 0, 0, 0.15), 0 0.025rem 0.05rem rgba(0, 0, 0, 0.15);
}
.md-typeset .mdx-iconsearch .md-input {
  background: var(--md-default-bg-color);
  box-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.07);
}
[data-md-color-scheme=slate] .md-typeset .mdx-iconsearch .md-input {
  background: var(--md-code-bg-color);
}
.md-typeset .mdx-iconsearch-result {
  max-height: 50vh;
  overflow-y: auto;
  backface-visibility: hidden;
  touch-action: pan-y;
  scrollbar-width: thin;
  scrollbar-color: var(--md-default-fg-color--lighter) transparent;
}
.md-typeset .mdx-iconsearch-result::-webkit-scrollbar {
  width: 0.2rem;
  height: 0.2rem;
}
.md-typeset .mdx-iconsearch-result::-webkit-scrollbar-thumb {
  background-color: var(--md-default-fg-color--lighter);
}
.md-typeset .mdx-iconsearch-result::-webkit-scrollbar-thumb:hover {
  background-color: var(--md-accent-fg-color);
}
.md-typeset .mdx-iconsearch-result__meta {
  position: absolute;
  top: 0.4rem;
  right: 0.6rem;
  color: var(--md-default-fg-color--lighter);
  font-size: 0.64rem;
}
.md-typeset .mdx-iconsearch-result__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.md-typeset .mdx-iconsearch-result__item {
  margin: 0;
  padding: 0.2rem 0.6rem;
  border-bottom: 0.05rem solid var(--md-default-fg-color--lightest);
}
.md-typeset .mdx-iconsearch-result__item:last-child {
  border-bottom: none;
}
.md-typeset .mdx-iconsearch-result__item > * {
  margin-right: 0.6rem;
}
.md-typeset .mdx-iconsearch-result__item img {
  width: 0.9rem;
  height: 0.9rem;
}
[data-md-color-scheme=slate] .md-typeset .mdx-iconsearch-result__item img[src*=squidfunk] {
  filter: invert(1);
}

.md-typeset .mdx-sponsorship__list {
  margin: 2em 0;
  overflow: auto;
}
.md-typeset .mdx-sponsorship__item {
  display: block;
  float: left;
  width: 3rem;
  height: 3rem;
  margin: 0.2rem;
  overflow: hidden;
  border-radius: 100%;
  transform: scale(1);
  transition: color 125ms, transform 125ms;
}
.md-typeset .mdx-sponsorship__item:focus, .md-typeset .mdx-sponsorship__item:hover {
  transform: scale(1.1);
}
.md-typeset .mdx-sponsorship__item:focus img, .md-typeset .mdx-sponsorship__item:hover img {
  filter: grayscale(0%);
}
.md-typeset .mdx-sponsorship__item--private {
  color: var(--md-default-fg-color--lighter);
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 3rem;
  text-align: center;
  background: var(--md-default-fg-color--lightest);
}
.md-typeset .mdx-sponsorship__item img {
  display: block;
  width: 100%;
  height: auto;
  filter: grayscale(100%);
  transition: filter 125ms;
}
.md-typeset .mdx-sponsorship-button {
  font-weight: 400;
}
.md-typeset .mdx-sponsorship-count,
.md-typeset .mdx-sponsorship-total {
  font-weight: 700;
}

/*# sourceMappingURL=main.css.map */